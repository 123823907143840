import { ref } from 'vue';

export const getConfig = () => {
  const adminConfig = ref();

  const salesChannleId =
    useSessionContext().sessionContext.value?.salesChannel.id;
  const languageId =
    useSessionContext().sessionContext.value?.salesChannel.languageId;

  const { apiInstance } = useShopwareContext();

  apiInstance.invoke
    .get(`/store-api/additional-address-line/${salesChannleId}/${languageId}`)
    .then((data) => (adminConfig.value = data));

  return { adminConfig };
};
